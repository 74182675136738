.cart__main-link {
  display: block;
  max-width: max-content;
  padding-right: 3em;
  padding-left: 3em;
  margin: 0 auto;
}

.cart__list {
  border-bottom: 1px solid #ced4da;
  isolation: isolate;
}

.cart__item {
  margin-bottom: 24px;

  @media (min-width: 768px) {
    margin-bottom: 0;
  }
}

.cart__controls {
  margin: 4rem 0 0;
  padding: 1rem 0 0;
  list-style: none;

  @media (min-width: 420px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
  }

  @media(min-width: 992px) {
    display: flex;
    flex-direction: row-reverse;
    gap: 1.6rem;
  }
}

.cart__controls-item {
  margin-bottom: 1.2rem;
  
  &:last-child {
    margin-bottom: 0;
  }

  @media(min-width: 420px) {
    margin-bottom: 0;
  }

  @media(min-width: 992px) {
    &:nth-child(2) {
      margin-left: auto;
    }
  }
}

.cart__button {
  display: block;
  width: 100%;
  white-space: nowrap;

  &::after {
    content: '';
    display: inline-block;
    margin-left: 0.5em;
    width: 1em;
    height: 1em;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
  }

  &--download-all::after{
    filter: invert(1);
    background-image: url("./download-all.svg");
  }

  &--check::after {
    filter: invert(1);
    background-position: center top 0.1em;
    background-image: url("./check.svg");
  }

  &--remove-all::after {
    background-position: center top 0.15em;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40 40'%3E%3Cpath stroke='white' stroke-width='3' stroke-linecap='round' d='M 10,10 L 30,30 M 30,10 L 10,30'%3E%3C/path%3E%3C/svg%3E");
  }
}