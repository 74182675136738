$container-max-widths: (
  sm: 100%,
  md: 100%,
  lg: 880px,
  xl: 1140px,
  xxl: 1140px
);

@import './node_modules/bootstrap/scss/bootstrap.scss';

.page {
  display: flex;
  flex-direction: column;
  min-height: 101vh;
}

.page__main {
  flex: 1 0 auto;
  padding-bottom: 90px;
  isolation: isolate;
}

.container--main-wrap {
  padding-top: 150px;

  @media (min-width: 601px) {
    padding-top: 100px;
  }
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  white-space: nowrap;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  overflow: hidden;
}