.scrollup {
  position: absolute;
  top: -72px;
  right: 0.75rem;
  display: grid;
  place-content: center;
  width: 56px;
  height: 56px;
  border: 1.5px solid currentColor;
  border-radius: 50%;
  color: #198754;
  background-color: white;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.4);
  transform: scale(0.5);
  opacity: 0;
  transition: box-shadow .2s ease-in, transform .2s ease-in, opacity .2s ease-in;

  &.show {
    transform: scale(1);
    opacity: 1;
    transition: box-shadow .2s ease-out, transform .2s ease-out, opacity .2s ease-out;
  }

  &.show:hover,
  &.show:focus {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    transform: scale(1.1);
  }

  @media (min-width: 1280px) {
    right: calc((100vw - 1260px) / 2);
  }
}

.scrollup__arrow {
  position: relative;
  display: block;
  width: 16px;
  height: 16px;
  transform: translateY(25%) rotate(45deg);

  &::before, 
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 100em;
    background-color: currentColor;
  }

  &::before {
    width: 100%;
    height: 2px;
  }

  &::after {
    width: 2px;
    height: 100%;
  }
}