.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background-color: #fff;
  box-shadow: 0 2px 24px rgba(0, 0, 0, 0.2);

  @media (max-width: 600px) {
    max-height: 140px;
    overflow: hidden;
    transition: max-height 0.4s ease-in-out;
  
    &--hide {
      max-height: 70px;
    }
  }
}

.header__container {
  position: relative;
}

.header__wrap {
  justify-content: space-between;
  @media (max-width: 600px) {
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-gap: 16px;
  }
}

.header__logo-wrap {
  @media (max-width: 600px) {
    max-width: max-content;
  }
}

.header__logo {
  width: 38px;
  border-radius: 4px;
}

.header__text {
  @media (max-width: 600px) {
    grid-column: 1 / span 2;
    grid-row: 2 / span 1;
  }
}

.header__cart-wrap {
  @media (max-width: 600px) {
    justify-self: end;
    max-width: max-content;
  }
}

.header__cart {
  position: relative;
  display: block;
  width: 38px;
  height: 38px;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  background-image: url('./cart.svg');
}

.header__badge {
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.2s ease-in-out, transform 0.1s ease-in-out;

  &--show {
    opacity: 1;
  }

  &--active {
    transform: scale(1.15);
  }
}