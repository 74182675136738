.modal {
  display: grid;
  align-items: center;
  max-height: 100vh;
  opacity: 0;
  overflow: hidden;
  transition: 0.3s opacity ease-in;
  
  &.show {
    opacity: 1;
    transition: 0.3s opacity ease-out;

    .modal__container {
      transform: scale(1);
      transition: 0.3s transform ease-out;
    }
  }
  &.fade {
    background-color: rgba(0,0,0,0.7);
  }
}

.modal__underlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.modal__container {
  position: relative;
  min-height: 0;
  transform: scale(0.3);
  transition: 0.3s transform ease-in;
}

.modal__title {
  font-weight: 200;
}

.modal__link {
  color: inherit;
  text-decoration-color: #198754;
  
  &:active {
    color: #198754;
  }

  &:visited {
    color: inherit;    
  }
}

.modal__close {
  display: block;
  width: 32px;
  height: 32px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40 40'%3E%3Cpath stroke='black' stroke-width='3' stroke-linecap='round' d='M 10,10 L 30,30 M 30,10 L 10,30'%3E%3C/path%3E%3C/svg%3E");
}

.modal__image {
  display: block;
  max-width: 100%;
  max-height: calc(100vh - 14rem);
  margin: 0 auto;
  border-radius: 4px;
}

.modal__header {
  border-bottom: none;
}

.modal__body {
  padding-top: 0;
  padding-bottom: 0;
}

.modal__footer {
  border-top: none;
}

.modal__addtocart {
  min-width: 10em;
}