.gallery-item {
  position: relative;
  border-radius: 4px;
  opacity: 1;
  transform: translateY(0);
  overflow: hidden;
  transition: 0.3s opacity ease-out, 0.3s transform ease-out;

  @media (hover: hover) {
    &:hover,
    &:focus,
    &:focus-within {
      .gallery-item__image-wrap::after {
        opacity: 0.7;
      }

      .gallery-item__addtocart {
        opacity: 0.7;

        &:hover,
        &:focus {
          opacity: 1;
          transform: translate(-50%, -50%) scale(1.1);
        }
      }
    }
  }

  &.in-cart .gallery-item__image-wrap::before {
    opacity: 1;
  }

  &.loading {
    opacity: 0;
    transform: translateY(25%);
  }
}

.gallery-item__image-wrap {
  position: relative;
  background-color: #eeeeee;

  &::before {
    content: "";
    position: absolute;
    top: -1px;
    left: -1px;
    width: 32px;
    height: 32px;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 100%;
    background-image: linear-gradient(to right top, rgb(0, 231, 123), lightgreen);
    clip-path: polygon(0 0, 100% 0, 0 100%);
    opacity: 0;
    z-index: 1;
    transition: opacity 0.2s ease-in-out;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #000000;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
}

.gallery-item__image {
  display: block;
  width: 100%;
  height: auto;
}

.gallery-item__link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.gallery-item__addtocart {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 0;
  padding: 0;
  width: 60px;
  height: 60px;
  border: none;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.7);
  background-repeat: no-repeat;
  background-size: 70%;
  background-image: url('./cart-plus.svg'), url('./cart-minus.svg');
  background-position: left 6px center, left 100px center;
  filter: invert(1);

  &--minus {
    background-position: left 100px center, left 6px center;
  }

  @media (hover: hover) {
    top: 50%;
    left: 50%;
    border-radius: 0;
    background-color: transparent;
    background-size: 100%;
    background-position: center, left 100px center;
    opacity: 0;
    transform: translate(-50%, -50%);
    transition: opacity 0.3s ease-in-out, background-color 0.3s ease-in-out, transform 0.2s ease-in-out;

    &--minus {
      background-position: left 100px center, center;
    }

    &:active {
      transform: translate(-50%, -50%) scale(1);
    }
  }
}
