.cart-item {
  position: relative;
  transition: opacity 0.3s ease-out;
  animation: fadeInUp 0.3s ease-out;

  &.hide {
    opacity: 0;
  }
}

.cart-item__image-wrap {
  position: relative;
  margin-bottom: 8px;
  border-radius: 0.25rem;
  overflow: hidden;
}

.cart-item__image {
  display: block;
  max-width: 100%;
  cursor: pointer;
}

.cart-item__button {
  position: absolute;
  right: 0.5rem;
  bottom: 0.5rem;
  background-color: #fff;
}

.cart-item__text {
  margin: 0;
  padding: 0;
  list-style: none;
}

.cart-item__link {
  color: inherit;
  text-decoration-color: #198754;
  
  &:active {
    color: #198754;
  }

  &:visited {
    color: inherit;    
  }
}

.cart-item__checkbox {
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  background-color: white;
  width: 32px;
  height: 32px;
  border-radius: 0.16rem;
  border: 1px solid #198754;
  cursor: pointer;

  &::before {
    content: "";
    position: absolute;
    top: -16px;
    right: -16px;
    bottom: -16px;
    left: -16px;
  }
}

.cart-item__checkbox-input {
  border: none;
}

.cart-item__checkbox-check {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 70%;
  background-image: url("./check.svg");
  opacity: 0;
  transform: translate(-50%, -50%);
}

.cart-item__checkbox-input:checked ~ .cart-item__checkbox-check {
  opacity: 1;
}

@keyframes fadeInUp {
  from {
    transform: translateY(10%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
