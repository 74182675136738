.loader {
  visibility: hidden;

  &--visible {
    visibility: visible; 
  }
}

.loader__image {
  display: block;
  width: 52px;
  height: 40px;
  margin: 0 auto;
}