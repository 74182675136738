.gallery-grid {
  display: grid;
  align-items: start;
  grid-gap: 8px;
  margin: 0;
  padding: 0;
  list-style: none;
}

.gallery-grid__list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.gallery-grid__item {
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }

  &--cart {
    margin-bottom: 24px;
  }
}
